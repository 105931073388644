.verification-box {
    box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
    border-radius: 0px 5px 5px 5px;
    padding: 2em;
    border-radius: 0px;
    margin-top: 3em;
}

.verification-box h4 {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0.5em;
    color: var(--teritary-color);
    line-height: 1.5;
    text-align: center;
}

.verification-box p {
    color: var(--teritary-color);
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;
}

.verification-item {
    margin: 2em 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.verification-item input {
    width: 3rem !important;
    height: 3rem;
    font-size: 1.8rem;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: var(--quaternary-color);
}

.verification-btn-sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.verification-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.verification-logo{
    height: 2.5em;
    object-fit: contain;
    margin: auto;
}

.verification-logo-sec{
    text-align: center;
}

.default-outline-btn {
    background-color: transparent !important;
    padding: 0.6em 2em !important;
    text-align: center;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    color: var(--quaternary-color) !important;
    display: inline-block;
    border: 1px solid var(--primary-color) !important;
    font-size: 1em;
    font-weight: 600;
}

.default-outline-btn:hover,
.default-outline-btn:focus,
.default-outline-btn:active {
    background-color: var(--primary-color) !important;
    color: var(--tertiary-color) !important;
}