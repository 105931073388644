.wallet-card{
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding:1.5em;
    position: relative;
}

.wallet-icon-sec{
    width: 4em;
    height: 4em;
    background-color: #15141B;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    /* margin-left: 2em; */
    border-left: 1px solid #9664FE;
}

.wallet-box{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    height: 100%;
}

.wallet-header-sec{
    padding: 2em 0em;
}

.wallet-info{
    display: flex;
    gap: 0.6em;
    flex-direction: column;
}

.wallet-info h6{
    margin-bottom: 0;
    color: #4F4E55;
    font-size: 0.9em;
    margin-top: 1em;
    font-weight: 500;
}

.wallet-info h3{
    margin-bottom: 0;
    font-size: 1.8em;
    font-weight: 600;
    color: var(--tertiary-color);
}

.wallet-info p{
    margin-bottom: 0;
    font-size: 0.8em;
    font-weight: 500;
    color: #FF0000;
    white-space: nowrap;
}

.wallet-card-left-border{
    outline: 3px solid #9664FE;
    position: absolute;
    height: 3.5em;
    left: 2px;
    top: 27px;
}

.wallet-btn-box{
    background: #FFE61B;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 2em;
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: space-between;
}

.wallet-btn-img-sec{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
}

.wallet-btn-card .add-money-btn{
    background-color: #211E2D;
    border-color: #211E2D;
    border-radius: 10px;
    padding: 1em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap:0.5em;
    color: var(--secondary-color);
    width: 100%;
}

.wallet-btn-card .add-money-btn:hover,
.wallet-btn-card .add-money-btn:focus,
.wallet-btn-card .add-money-btn:active{
    background-color: #211E2D;
    border-color: #211E2D;
    color: var(--secondary-color);
}

.wallet-btn-card .withdraw-money-btn{
    background-color: #1870EF;
    border-color: #1870EF;
    border-radius: 10px;
    padding: 1em 2em;
    display: flex;
    align-items: center;
    gap:0.5em;
    color: var(--secondary-color);
    width: 100%;
    justify-content: center;
    text-align: center;
}

.wallet-btn-card .withdraw-money-btn:hover,
.wallet-btn-card .withdraw-money-btn:focus,
.wallet-btn-card .withdraw-money-btn:active{
    background-color: #1870EF;
    border-color: #1870EF;
    color: var(--secondary-color);
}

.wallet-btn-card{
    display: flex;
    gap: 1em;
    flex-direction: column;
}