.become-a-seller-info h1 {
    font-size: 2.5em;
    font-weight: 700;
    color: var(--tertiary-color);
    line-height: 1;
    margin-bottom: 0.5em;
}

.become-a-seller-info h1 span {
    color: var(--primary-color);
}

.become-a-seller-info p {
    color: var(--quaternary-color);
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
}

.become-a-seller-img-sec {
    margin-top: 2em;
}

.become-a-seller-img {
    width: 100%;
}

.become-a-seller-left-sec {
    padding-right: 1.5em;
    padding-top: 2em;
}

.become-a-seller-steps-badge {
    background-color: var(--primary-color);
    padding: 0.5em 1em;
    border-radius: 50px;
    display: inline-block;
    font-size: 0.9em;
}

.become-a-seller-header-sec {
    padding-top: 2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2em;
    /* margin-bottom: 2em; */
}

.become-a-seller-step-count-sec {
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: center;
    margin-bottom: 1em;
}

.become-a-seller-step-count-bg {
    background-color: var(--primary-color);
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4em;
    border-radius: 50%;
}

.become-a-seller-step-count-sec span {
    font-size: 1.2em;
    font-weight: 600;
    color: var(--tertiary-color);
}

.import-rules-list li {
    padding-bottom: 1em;
    color: var(--quaternary-color);
    font-size: 0.85em;
    font-weight: 400;
    line-height: 1.5;
}

.import-rules-list li:last-child {
    padding-bottom: 0;
}

.become-a-seller-body-sec {
    padding: 1.5em;
}

.import-rules-list {
    margin-bottom: 0;
    padding-bottom: 1.5em;
}

.account-certification-list li {
    display: flex;
    gap: 1em;
    padding-bottom: 1em;
    align-items: center;
    color: var(--quaternary-color);
    font-size: 0.85em;
    font-weight: 400;
    line-height: 1.5;
}

.account-certification-list li:last-child {
    padding-bottom: 0;
}

.account-certification-card {
    display: flex;
    gap: 2em;
    align-items: center;
    padding: 2em 0;
}

.upload-document-box {
    box-shadow: inset 0px 0px 10px rgb(0 0 0 / 10%);
    border-radius: 10px;
    padding: 1em;
    flex: 1;
}

.account-certification-upload-document-sec {
    display: flex;
    gap: 1em;
    align-items: center;
}

.upload-document-card h4 {
    font-size: 0.8em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-bottom: 0;
}

.upload-document-card {
    width: 10em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.upload-document-card p {
    color: var(--quaternary-color);
    font-size: 0.7em;
    font-weight: 400;
    line-height: 1.5;
}

.upload-document-icon {
    width: 2em;
}

.account-certification-upload-document-sec {
    padding: 2em 0;
}

.account-certification-note-sec p {
    color: var(--quaternary-color);
    font-size: 0.85em;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
}


.awaiting-for-approval-info h4 {
    font-size: 0.9em;
    font-weight: 600;
    color: var(--active-color);
    margin-bottom: 0.5em;
}

.awaiting-for-approval-info p {
    color: var(--quaternary-color);
    font-size: 0.85em;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
}

.awaiting-for-approval-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
    text-align: center;
    padding: 2em 0;
}

.become-a-seller-btn-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.seller-bank-box p {
    color: var(--quaternary-color);
    font-size: 0.85em;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
}

.seller-bank-form {
    padding: 2em 0;
}

.seller-bank-form .form-label {
    font-size: 0.8em;
    font-weight: 600;
    color: var(--active-color);
    margin-bottom: 0.5em;
}

.seller-bank-form .form-control::placeholder {
    font-size: 1em;
}

.seller-bank-form input[type=checkbox] {
    transform: scale(1.5);
}

.seller-bank-form input[type=checkbox] {
    margin-right: 1em;
    cursor: pointer;

    visibility: hidden;
}

.seller-bank-form input[type=checkbox]:after,
.seller-bank-form input[type=checkbox]::after {
    content: " ";
    background-color: #fff;


    color: var(--tertiary-color);

    height: 0.9em;
    visibility: visible;
    border: 1px solid rgba(0, 0, 0, 0.1);

    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.seller-bank-form input[type=checkbox]:checked:after,
.seller-bank-form input[type=checkbox]:checked::after {
    content: "\2714";
    padding: -5px;
    font-weight: bold;
}

.seller-bank-form .form-control:focus {
    box-shadow: none;
    border-color: var(--primary-color);
}

.seller-bank-form .form-control {
    border: 1px solid #e7e7e7;
    box-shadow: inherit;
    color: var(----active-color);
    height: 50px;
    background: rgba(57, 57, 57, .07);
}

.seller-update-billing-info-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 1em;
    padding: 2em 0;
}

.seller-update-billing-info-box h4 {
    font-size: 0.9em;
    font-weight: 600;
    color: var(--active-color);
    margin-bottom: 0.5em;
}

.seller-update-billing-info-box p {
    color: var(--quaternary-color);
    font-size: 0.85em;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
}

.upload-doc-card {
    padding-bottom: 0;
    padding-right: 0;

}

.upload-doc-sec .upload-doc-box {
    border-radius: 4px;
    height: 100%;
    position: relative;
    padding: 1em 0;
    transition: all .5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-card-color);
    border-radius: 6px;
}

.doc-upload-img {
    width: 25em;
    height: 8.3em;
    object-fit: contain;
}

.file-upload-wrap {
    position: relative;
}

.file-upload-wrap .file-upload-input {
    border-radius: 4px;
    width: 100%;
    border: 2px dashed rgba(128, 137, 150, .2);
    height: 120px;
    text-align: center;
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 10rem 0 0;
    overflow: hidden;
    z-index: 1;
    transition: all .3s;
}

i.fa.fa-upload {
    margin-right: 6px;
}

.file-upload-wrap .file-upload-text {
    position: absolute;
    top: 4em;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    text-align: center;
    font-size: 18px;
    color: rgba(128, 137, 150, .8);
}

.theme-btn {
    background-color: var(--primary-color) !important;
}

button.theme-btn.btn.btn-primary {
    border: 0px;
}

.upload-doc-sec .upload-doc-box {
    border-radius: 4px;
    height: 100%;
    position: relative;
    padding: 1em 0;
    transition: all .5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    border-radius: 6px;
}

.title-wrap .title {
    font-size: 1.2rem;
    margin-top: .5em;
    font-weight: 700;
    line-height: .5em;
    text-transform: uppercase;

}

.go-back button {
    background-color: var(--primary-color);
    padding: 0.5em 1em;
    border-radius: 50px;
    display: inline-block;
    font-size: 0.9em;
    border: 0px;
    color: var(--tertiary-color);
}

.go-back button:hover {
    background-color: var(--primary-color);
    color: var(--tertiary-color);
}

.approve-back {
    margin-right: 10px;
}