/* .live-streaming-sec{
    height: calc(100vh - 101.8px);
} */
.live-stream-left-sec {
    background-color: var(--quaternary-color);
    padding: 2em 1em;
    height: 100%;
}

.live-strem-title h3 {
    font-size: 1.2em;
    color: var(--secondary-color);
    margin: 0 0 1em;
}

.live-stream-tab .nav-pills {
    border-bottom: 1px solid rgba(79, 78, 85, 0.2);
    flex-wrap: nowrap;
}

.live-stream-tab .nav-pills .nav-link {
    font-size: 0.8em;
    color: #4F4E55;
}

.live-stream-tab .nav-pills .nav-link.active {
    color: var(--secondary-color);
    border-bottom: 2px solid var(--primary-color);
    background-color: transparent;
    border-radius: 0;
    padding: 0.8em;
}

.live-streaming-tab-details .input-group-text {
    background-color: transparent;
    border: none;
    padding-right: 0;

}

.live-streaming-tab-details input.form-control {
    background-color: transparent;
    border: none;

}

.live-streaming-tab-details input.form-control:focus {
    box-shadow: none;
}

.live-streaming-tab-details .input-group {
    background-color: var(--secondary-color);
    border-radius: 50px;
    width: 100%;
    height: 3em;
}

.live-streaming-tab-details {
    margin: 2em 0 0;
}

.live-streaming-box {
    margin: 2em 0 0;
}

.live-streaming-card {
    display: flex;
    align-items: center;
    gap: 1em;
    background-color: var(--tertiary-color);
    padding: 1em;
    border-radius: 10px;
}

.live-streaming-card-details {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.live-streaming-card-details h5 {
    color: var(--secondary-color);
    margin: 0;
    font-size: 0.9em;
}

.live-streaming-card-details p {
    color: #FF3D3D;
    font-size: 0.8em;
    margin: 0;
}

.live-streaming-card-details h3 {
    color: var(--primary-color);
    margin: 0;
    font-size: 1.5em;

}

.live-streaming-card-img img {
    max-width: 5em;
}

.live-streaming-box {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.live-streaming-middle-sec {
    background-color: var(--quaternary-color);
    height: calc(100vh - 101.8px);
}

.live-streaming-right-sec {
    background-color: var(--quaternary-color);
    height: calc(100vh - 101.8px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.live-streaming-category-card-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2em;
    margin: 2em 0;
}

.live-sec-img img.live-middle-img {
    width: 100%;
    height: 39em;
    margin: auto;
    object-fit: cover;
}


.live-img img {
    width: 4em;
    border-radius: 50px;
    height: auto;
}

.live-profile-img {
    display: flex;
    gap: 1em;
    align-items: center;
}

.live-sec-img {
    position: relative;
}

.live-sec-img::before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    content: "";
    background: radial-gradient(61.72% 61.72% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}

.live-stream-profile {
    position: absolute;
    top: 0;
    justify-content: space-between;
    width: 100%;
    z-index: 999;
    padding: 1em;
    align-items: center;
}

.live-profile-img-content h4 {
    font-size: 1.2em;
    color: var(--primary-color);
    margin: 0 0 0.3em;
}

.live-profile-img-content p {
    color: var(--secondary-color);
    font-size: 1.1em;
    margin: 0;
}

.live-stream-btn {
    position: absolute;
    bottom: 0px;
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    padding: 1em;
    align-items: center;
    z-index: 999;
}

.live-icons {
    display: flex;
    gap: 2em;
}

.live-eye-icon {
    display: flex;
    gap: 0.3em;
    align-items: center;
}

.live-icons h4 {
    background-color: #FF3D3D;
    padding: 0.5em;
    color: var(--secondary-color);
    border-radius: 5px;
    font-size: 0.8em;
    margin: 0;
}

.live-eye-icon span {
    color: var(--secondary-color);
}

.live-bottom-icon a img {
    max-width: 2em;
}

.live-chat-profile {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.live-stream-chat-sec {
    padding: 1.5em;

    align-items: flex-end;
}

.live-chat-img {
    display: flex;
    align-items: baseline;
    gap: 1em;
}

.live-chat-profile-content h5 {
    color: var(--primary-color);
    font-size: 0.8em;
}

.live-chat-profile-content p {
    color: var(--secondary-color);
    font-size: 0.75em;
    margin: 0;
}

.live-chat-time p {
    color: var(--secondary-color);
    font-size: 0.8em;
    white-space: nowrap;
    margin: 0;
}

.live-chat-profile-img img {
    max-width: 3em;
    border-radius: 50%;
    height: 3em;
}

.comment-input input {
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    height: 3em;
    color: var(--secondary-color) !important;
}

.comment-input input:focus {
    background-color: transparent;
    box-shadow: none;
    border-color: rgba(255, 255, 255, 0.2);
}

.live-streaming-form {
    margin: 2em 0 0;
}

.live-streaming-form .input-group {
    background-color: var(--secondary-color);
    border-radius: 50px;
    width: 100%;
    height: 3em;
}

.live-streaming-form .input-group-text {
    background-color: transparent;
    border: none;
    padding-right: 0;
}

.live-streaming-form input.form-control {
    background-color: transparent;
    border: none;
}

.live-streaming-form input.form-control:focus {
    box-shadow: none;
}

.product-opt {
    padding-left: 5px !important;
}

.live-video-header {
    padding: 2em 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.live-video-header-btn-sec {
    display: flex;
    align-items: center;
    gap: 1em;
}

.live-video-history-box table thead th:last-child {
    text-align: center;
}

.live-video-submit-btn {
    text-align: center;
}

.live-video-history-box .order-list-table table tbody tr td {
    padding: 2em 1em;
}

.go-live-header-info p {
    margin-bottom: 0;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 0.5em;
}

.golive-upload-produts {
    border: 0 !important;
    box-shadow: inherit;
    background: rgba(57, 57, 57, .07);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.375rem;
    gap: 0.5em;
}

.go-live-sec {
    padding-bottom: 2em;
}

.go-live-date-time-picker input {
    border: 0 !important;
    box-shadow: inherit;
    color: var(--active-color);
    height: 50px;
    background: rgba(57, 57, 57, .07);
    width: 100%;
    padding: 0.375rem 0.75rem;
}

.go-live-date-time-picker .rmdp-container {
    width: 100%;
}

.go-live-product-box ul {
    list-style-type: none;
    grid-template-columns: repeat(2, 1fr);
    display: grid;

}

.go-live-product-sec {
    margin-top: 2em;
}

.go-live-product-box {
    padding-top: 1em;
}

.go-live-product-sec .btn-primary:active,
.go-live-product-sec .btn-primary:focus {
    background-color: rgba(57, 57, 57, .07) !important;
}

.go-live-product-sec .btn-primary:not(:disabled):not(.disabled):active {
    background-color: rgba(57, 57, 57, .07) !important;
}

.go-live-product-sec .btn {
    /* height: 50px!important; */
    border: 0 !important;
    outline: none !important;
    box-shadow: none;
    padding: 20px !important;
    font-size: 1rem !important;
    border-radius: 0.37rem !important;
    color: #999 !important;
    background: rgba(57, 57, 57, .07) !important;
    width: 100%;
    text-align: left;
    margin-bottom: 2em;
}

.go-live-product-box input[type="checkbox"] {
    display: none;
}

.go-live-product-box label {
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    position: relative;
    margin: 10px;
    cursor: pointer;
    padding: 0.5em;
}

.products-info {
    padding-left: 1em;
}

.go-live-product-box label:before {
    background-color: var(--secondary-color);
    color: var(--secondary-color);
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    transition-duration: 0.4s;
    transform: scale(0);
}

.go-live-product-box label img {
    height: 100px;
    width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
    border-radius: 10px;
}

.go-live-product-box :checked+label {
    border-color: #ddd;
    border-radius: 15px;
}

.go-live-product-box :checked+label:before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
}

.go-live-product-box :checked+label img {
    transform: scale(0.9);
    /* box-shadow: 0 0 5px #333; */
}

.products-info p {
    color: #666;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 4em;
    /* width: 270px; */
    display: block;
}

.products-info h5 {
    font-weight: 700;
    font-size: 0.9em;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 4em;
    /* width: 270px; */
    display: block;
}

.go-live-img {
    max-width: 25em;
    margin: auto;
}

.img-order {
    width: 7em;
    height: 7em;
}

td.order-pic {
    display: flex;
    gap: 1em;
}

.upload-img-preview-sec {
    margin-top: 1em;
    margin-bottom: 1em;
}

.upload-img-preview {
    height: 15em;
    object-fit: cover;
    width: 20em;
    border-radius: 10px;
}

.live-profile-img-content h4 a {
    font-size: 1.2em;
    color: var(--primary-color);
    margin: 0 0 0.3em;
}

#agora_local {
    position: relative;
    background-color: black;
    background-image: url(https://www.offlinemarketing.co.uk/wp-content/uploads/2020/01/shutterstock_1572522772-scaled-1.jpg);
    background-size: cover;
    background-position: center;
}

.react-scroll-to-bottom--css-xpeya-1n7m0yu {
    height: 100%;
    overflow-y: auto;
    width: 100%;
}

.live-stream-chat-sec {
    flex: 1 1;
    overflow-y: auto;
    padding: 16px;
}

.chat-area {
    flex: 1 1;
    overflow-y: auto;
    padding: 16px;
}

.chat-typing-area-wrapper {
    display: flex;
    border-radius: 10px;
    padding: 8px;
    box-shadow: var(--navigation-box-shadow);
    background-color: var(--message-bg);
}

.send-button {
    color: var(--secondary-color);
    background-color: var(--primary-color);
    border-radius: 8px;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 13px; */
}

.chat-typing-area {
    align-items: center !important;
}

input.chat-input.form-control {
    background-color: initial !important;
    border: 1px solid hsla(0, 0%, 100%, .2) !important;
    border-radius: 50px !important;
    color: #ffffff !important;
    height: 3em !important;
    padding: .375rem .75rem !important;
}

.chat-typing-area {
    justify-content: space-between;
    gap: 6px;
}

.send-button {
    color: var(--secondary-color) !important;
    background-color: var(--primary-color) !important;
    border-radius: 8px !important;
    border: none;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    font-size: 13px !important;
    width: 48px !important;
    height: 36px !important;
}

.scroll-comment-sec {
    padding-bottom: 2em;
}

.scroll-comment-sec::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.scroll-comment-sec::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.scroll-comment-sec::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
}

.live-streaming-middle-sec {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}

.right-side {
    background-color: #000;
}

.chat-header-new {
    color: #fff !important;
}

button.btn.btn-primary {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 5px 6px;
}