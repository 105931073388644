.listing-tab .tab .nav-tabs {
    border-bottom: 2px solid #e8e8e8;
}

.listing-tab .tab .nav-tabs li a {
    padding: 10px 20px;
    padding-left: 0px !important;
    margin: 0 5px 1px 0;
    background: var(--secondary-color);
    font-size: 16px;
    font-weight: 500;
    color: var(--quaternary-color);
    text-align: center;
    border: none;
    border-radius: 0;
    z-index: 2;
    position: relative;
    transition: all 0.3s ease 0s;
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.listing-tab .tab .nav-tabs li.active a:after {
    content: "";
    width: 100%;
    height: 3px;
    background: var(--primary-color) !important;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease 0s;
}

.listing-tab .tab .nav-tabs {
    border-bottom: 2px solid #e8e8e8;
}

.fade:not(.show) {
    opacity: inherit !important;
}

.follower-lists {
    -webkit-column-gap: 48px;
    -moz-column-gap: 48px;
    column-gap: 48px;
    padding: 0 0 12px;
}

.follower-subscription-lists {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 1em 0;
    width: 100%;
}

.follower-subscription-inner {
    width: 100%;
    border-radius: 6px;
    position: relative;
    padding: 0 0px 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid rgba(138, 150, 163, 0.25);
}

span.b-btn-text {
    white-space: normal;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 60px);
    flex: 0 0 calc(100% - 60px);
}

.follower-wrapper {
    position: relative;
}

img.follower-cover {
    height: 120px;
    width: calc(100% + 24px);
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 0px;
    border-radius: 6px 6px 0 0;
}

.follower-wrapper:after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0;
    bottom: 0;
    background: -webkit-gradient(linear,
            left top,
            left bottom,
            from(transparent),
            to(rgba(0, 0, 0, 0.4)));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.4));
    border-radius: 6px 6px 0 0;
}

.follower-profile-status a.btn.dropdown-toggle.btn-link svg.g-icon.has-tooltip {
    color: var(--secondary-color);
}

.follower-profile-header {
    padding-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-top: -45px;
}

span.follower-profile-avatar {
    width: 100px;
    height: 100px;
    border: 2px solid var(--bg-color);
    font-size: 36px;
    margin-right: 14px;
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 8px;
    border-radius: 1000px;
    display: block;
}

span.follower-profile-avatar img {
    border-radius: inherit;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.follower-info {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-top: 2px;
    padding-bottom: 2px;
    min-width: 0;
    padding-bottom: 0px;
}

.follower-profile-status {
    margin-right: -4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 15px;
}

.follower-status-text {
    font-size: 14px;
    line-height: 18px;
    color: #fefefe;
    padding: 0 0 2px;
}

.follower-profile-toggle-dropdown {
    margin-left: auto;
    margin-right: -10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: static;
}

.follower-profile-toggle-dropdown .user-dropdown-dots {
    background: transparent !important;
    box-shadow: none !important;
}

.dropdown-menu.show:after {
    right: 4px !important;
    left: unset;
}

.dropdown-menu.show {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background: var(--secondary-color);
    border: 1px solid rgba(138, 150, 163, 0.25);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0;
}

.dropdown-menu.show .media {
    margin: 0;
}

.follower-profile-toggle-dropdown .dropdown-menu.show {
    transform: translate(-260px, 47px) !important;
}

.follower-wrapper-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.follower-profile-names {
    max-width: calc(100% - 70px);
}

.follower-name-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 2px;
}

.follower-name-row a {
    min-width: 0;
    display: block;
}

.follower-user-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    position: relative;
    font-size: 19px;
    line-height: 24px;
    padding-right: 22px;
    font-weight: 500;
    color: var(--tertiary-color);
}

.vertical-dots {
    transform: rotate(90deg) translateY(15px);
    margin-left: 19px;
    position: relative;
    z-index: 1;
    margin-top: 10px;
    fill: var(--secondary-color);
}

button#dropdown-basic {
    border: 0;
}

/* .new-dropdown-language .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: #ffffff !important;
    border-color: transparent !important;
} */

.dropdown-menu.show:after {
    position: absolute;
    content: "";
    background: inherit;
    width: 18px;
    height: 18px;
    border-right: 1px solid rgba(138, 150, 163, 0.25);
    border-bottom: 1px solid rgba(138, 150, 163, 0.25);
    z-index: -1;
}

.dropdown-menu.show:after {
    top: -10px;
    bottom: 0;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.16);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.16);
}

.dropdown-menu.dropdown-menu-right li a {
    padding: 10px 20px;
    font-size: 1em !important;
    width: 100%;
    font-weight: 600 !important;
}

.dropdown-menu.dropdown-menu-right li a {
    text-align-last: auto !important;
    white-space: normal;
    padding: 14px 16px;
}

.follower-profile-toggle-dropdown .dropdown-menu.show {
    transform: translate(-260px, 47px) !important;
}

button.g-btn.m-rounded.m-border.m-icon.m-icon-only.m-colored.has-tooltip {
    width: 48px;
    height: 48px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48px;
    flex: 0 0 48px;
    padding: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 8px;
    border-radius: 1000px;
    background-color: transparent;
    border: 1px solid rgba(138, 150, 163, 0.25);
    color: var(--primary-color);
    -webkit-transition: color 0.15s, background-color 0.15s,
        -webkit-box-shadow 0.15s;
    transition: color 0.15s, background-color 0.15s, -webkit-box-shadow 0.15s;
    transition: color 0.15s, background-color 0.15s, box-shadow 0.15s;
    transition: color 0.15s, background-color 0.15s, box-shadow 0.15s,
        -webkit-box-shadow 0.15s;
    position: relative;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid rgba(138, 150, 163, 0.25);
}

.dropdown-menu.dropdown-menu-right li a {
    padding: 10px 20px;
    font-size: 1em !important;
    width: 100%;
    font-weight: 600 !important;
    color: #333;
}

.show {
    display: block !important;
}

.dropdown-menu.dropdown-menu-right li a {
    text-align-last: auto !important;
    white-space: normal;
    padding: 8px 16px;
    display: block;
}

.dropdown-menu.show {
    min-width: 300px;
}

.dropdown-toggle::after {
    display: none !important;

}

.follower-user-id {
    color: #8a96a3 !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.btn.gradient-btn.gradientcolor {
    background-color: #ffe61b;
    border: 0px;
    width: 100%;
    font-size: 14px;
    padding: 12px;
    margin: 0px 10px;
    border-radius: 20px;
}

span.b-btn-text {
    color: #000;
    font-weight: 600;
}

.lists-button-group {
    margin: 0 0 12px;
    padding: 18px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.btn.gradient-btn.gradientcolor:active {
    background-color: #ffe61b;
}

.nav-pills .nav-link.active {
    background-color: transparent;
    border-bottom: 4px solid var(--primary-color);
    border-radius: 0;
    color: var(--quaternary-color);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #000 !important;
    background-color: #fff !important;
}

.order-tab-img span {
    color: #000 !important;
}

.infinite-scroll-component.custom-infinite-scroll {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    overflow: hidden !important;
}