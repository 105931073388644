.auth-page-sec {
    min-height: 100vh;
    background-color: var(--secondary-color);
    display: flex;
    width: 100%;
}


.auth-page-left-sec {
    width: 50%;
    padding: 0;
    position: relative;
}

.auth-page-right-sec {
    width: 50%;
    background-color: var(--secondary-color);
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 60px 30px 52px;
}

.auth-slider-img {
    min-height: 100vh;
    object-fit: cover;
    width: 100%;
}

.auth-page-left-sec .slick-dots {
    bottom: 50px !important;
}

.auth-nav-switch-sec {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    display: flex;
    gap: 0.8em;
    flex-direction: column;
}

.auth-nav-switch-sec a {
    text-align: right;
    display: block;
    color: var(--secondary-color);
    font-size: 1em;
    font-weight: 500;
    padding: 20px 30px;
    background-color: var(--secondary-color);
    border-radius: 45px 0 0 45px;
    line-height: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: var(--tertiary-color);
}

.auth-nav-switch-sec a:hover,
.auth-nav-switch-sec a:focus {
    color: var(--tertiary-color);
}

.auth-nav-switch-sec a.login-link.active {
    background-color: var(--tertiary-color);
    color: var(--secondary-color);
}

.auth-details-sec {
    max-width: 450px;
    width: 100%;
    padding: 2em;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
    border-radius: 0px 5px 5px 5px;
}

.auth-logo {
    display: block;
    margin-bottom: 1.5em;
    max-width: 40vw;
}

.auth-info h2 {
    font-size: 1.5em;
    margin: 0 0 0.3em;
    font-weight: 500;
    color: var(--tertiary-color);
}

.auth-info p {
    font-size: 0.8em;
    margin: 0;
    font-weight: 300;
    color: var(--tertiary-color);
    line-height: 1.8;
}

.auth-form-sec .form-control {
    border: 1px solid #e7e7e7;
    box-shadow: inherit;
    color: var(--active-color);
    height: 50px;
    background: var(--secondary-color) !important;
}

.auth-form-sec .form-control::placeholder {
    font-size: 0.9em;
}

.auth-form-sec .form-control:focus {
    box-shadow: none;
    border-color: var(--primary-color);
    background: var(--secondary-color) !important;
}

.auth-form-sec .input-group .form-control {
    border-right: 0 !important;
}

.auth-form-sec .input-group-text {
    background: var(--secondary-color) !important;
    border: 1px solid #e7e7e7;
    border-left: 0 !important;
}

.auth-form-sec label {
    font-size: 0.8em;
    font-weight: 600;
    color: var(--active-color);
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.auth-info {
    margin-bottom: 1em;
}

.pass-eye-open {
    max-width: 1.5em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.forgot-password-link-sec {
    padding: 1.5em 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.forgot-password-link-sec a {
    font-size: 0.9em;
    font-weight: 500;
    color: var(--bs-blue) !important;
}

.auth-footer-link-sec h4 {
    font-size: 0.9em;
    font-weight: 500;
    color: var(--tertiary-color);
}

.auth-footer-link-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1.5em;
}

.auth-btn-sec {
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-logo {
    max-width: 3em;
}

.auth-social-btn-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-top: 1.5em;
    margin-bottom: 0em;
}

.auth-social-btn-sec button {
    border: 0px;
    background: none;
}