.upload-document-box{
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 2em;
    margin-top: 2em;
}

.upload-document-box h3{
    font-size: 1em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 2em;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper .btn {
    background-color: #1870EF;
    color: var(--secondary-color);
    padding: 8px 20px;
    border-radius: 10px;
    font-size: 1em;
    font-weight: 600;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.upload-document-card-new{
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    text-align: center;
    position: relative;
}

.upload-document-note-sec h6{
    color: #15141B;
    font-weight: 600;
    font-size: 0.85em;
    margin-bottom: 1em;
}

.upload-document-card-new p{
    color: #15141B;
    font-size: 0.8em;
    margin-bottom: 0;
    font-weight: 400;
}

.upload-document-name-sec h5{
    color: rgba(79, 78, 85, 0.6);
    font-size: 0.9em;
    font-weight: 500;
    margin-bottom: 0;
}

.upload-document-name-sec{
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.upload-document-success-sec{
    background-color: #FFD400;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
}

.upload-document-pending-sec{
    background-color: #FFD400;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
}