.other-profile-banner {
    background-image: url(../../../public/images/profile/banner.png);
    height: 25vh;
}

.other-profile-content {
    margin: -5em 0 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;

}

.other-profile-follower {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5em;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1em 0 0;
}

.other-profile-content img {
    max-width: 10em;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;
}

.other-profile-content h3 {
    font-size: 1.8em;
    font-weight: 500;
    margin: 0;
    color: var(--tertiary-color);
}

.other-profile-content p {
    font-size: 1.1em;
    font-weight: 300;
    margin: 0;
    color: var(--tertiary-color);
}

.other-profile-follower h4 {
    font-size: 1.2em;
    color: var(--tertiary-color);
    font-weight: 500;
    margin: 0;
    display: flex;
    gap: 0.5em;
}
.other-profile-follower h4 a {
    color: var(--tertiary-color);
}

.other-profile-follower h4 span {
    color: #E49F05;
    font-weight: 400;
}

.other-profile-card-list {
    margin: 3em 0 0;
}

.accordion-border {
    border-bottom: 1px solid rgba(79, 78, 85, 0.2);
    padding: 1em 0 0;
}

.home-page-left-sec.profile-sidebar {
    padding: 0 0 2em;

}

.profile-sidebar-sec {
    padding: 0 0 2em;
}

.profile-sidebar-banner-img img {
    height: 7em;
}

/* .profile-sidebar-image img{
    max-width: 5em;
    border-radius: 50%;
    margin:0 auto 0.5em;
} */
.profile-sidebar-image h3 {
    font-size: 1.4em;
    color: var(--secondary-color);
    margin: 0;
    font-weight: 500;
    text-align: center;
}

.profile-sidebar-image {
    margin: -2em 0 0;
}

.profile-sidebar-right-sec {
    padding: 2em;
}

.profile-sidebar-tab .nav-pills .nav-link {
    color: var(--quaternary-color);
    font-size: 1.1em;
    font-weight: 400;
}

.profile-sidebar-tab .nav-pills .nav-link.active {
    background-color: transparent;
    border-bottom: 4px solid var(--primary-color);
    border-radius: 0;
    color: var(--quaternary-color);
}

.profile-sidebar-tab .nav-pills .nav-link:focus-visible {
    outline-offset: 0px;
    outline: -webkit-focus-ring-color auto 0px;
    outline: none !important;
}

.profile-sidebar-tab .nav-pills {
    gap: 1em;
}

.profile-sidebar-right-detail-sec {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0px 5px 5px 5px;
    padding: 2em;
}

.profile-right-box h3 {
    font-size: 1.3em;
    margin: 0 0 0.3em;
    font-weight: 500;
    color: var(--tertiary-color);
}

.profile-right-box p {
    font-size: 0.8em;
    margin: 0;
    font-weight: 300;
    color: var(--tertiary-color);
    line-height: 1.8;
}

.profile-right-box {
    padding: 0 0 1.5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.profile-details-form {
    margin: 2em 0 0;
}

.profile-details-form form .add-bank-details textarea#exampleForm\.ControlTextarea1 {
    height: 7em !important;
}

.profile-social-link .form-control,
.profile-social-link .input-group-text {
    background-color: rgba(57, 57, 57, .07);
    border: none;
}

.profile-social-link .form-control:focus {
    box-shadow: none;
    background-color: unset;
}

.profile-social-link .input-group {
    border: 1px solid #e7e7e7;
    box-shadow: inherit;
    color: var(--active-color);
    height: 50px;
    background: rgba(57, 57, 57, .07);
    border-radius: 5px;
}

.image-input {
    display: none !important;
}

.input-edit-img {
    background-color: var(--primary-color);
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    position: absolute;
    bottom: 23px;
    right: 143px;
}

.profile-img-input {
    position: relative;
    text-align: center;
}

.profile-sidebar-banner-img {
    position: relative;
    cursor: pointer;
}

.input-edit-banner-img {
    background-color: var(--primary-color);
    width: 1.5em;
    height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    position: absolute;
    top: 14px;
    right: 14px;
}

.edit-img {
    width: 1em;
    height: unset !important;
}

.profile-select-img {
    max-width: 5em;
    border-radius: 50%;
}

.profile-sidebar-image .form-label,
.profile-sidebar-banner-img .form-label {
    cursor: pointer;
}

.profile-social-link .input-group-text img {
    width: 1.5em;
}

.profile-details-form .form-control {
    border: 0!important;
    box-shadow: inherit;
    color: var(--active-color);
    height: 50px;
    background: rgba(57, 57, 57, .07);
}

.profile-img-input .img {
    max-width: 5em;
    border-radius: 50%;
}

.list-unstyled li .nav-link.active{
    font-size: 1.1rem;
    font-weight: 800;
    padding: 8px;
}