.home-page-left-sec {
    background-color: #191A23;
    padding: 2em 0;
    height: 100%;
}

.left-side-title h3 {
    font-size: 1.2em;
    color: #696969;
    margin: 0;
    font-weight: 600;
}

.left-side-title {
    padding: 0 2em 2em;
}

.category-list-accordion .accordion-item {
    background-color: transparent;
    border: none;
}

.category-list-accordion .accordion-item .accordion-button {
    background-color: transparent;
    color: var(--secondary-color);
    padding: 1.5em 2em;
    border-top: 1px solid rgba(79, 78, 85, 0.2);
    border-bottom: 1px solid rgba(79, 78, 85, 0.2);
    border-right: none;
    border-left: none;
    border-radius: 0 !important;
    font-weight: 500;

}

.category-list-accordion .accordion-item .accordion-header {
    font-weight: 300;
}

.category-list-accordion .accordion-item .accordion-button:focus {
    box-shadow: none;
}

.category-list-accordion .accordion-button:not(.collapsed)::after {
    background-image: url(../../../public/images/up-arrow.svg);
}

.category-list-accordion .accordion-button::after {
    background-image: url(../../../public/images/down-arrow.svg);
}

.category-list-accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
    background-color: var(--active-color);
    border-left: 3px solid var(--primary-color);

}

.category-list-accordion .category-list ul li a {
    font-size: 0.95em;
    color: var(--secondary-color);
    font-weight: 400;
}

.category-list-accordion .category-list ul li {
    padding: 1em 1em 1em 5em;
}

.category-list-accordion .accordion-body {
    padding: 0;
}

.sidebar-footer-list {
    padding: 1em 0;
}

.sidebar-footer-list ul li {
    padding: 0.8em;
}

.sidebar-footer-list ul li a {
    display: flex;
    gap: 1em;
    align-items: center;
}

.sidebar-footer-list ul li a span {
    font-size: 0.95em;
    color: var(--secondary-color);
    font-weight: 400;
}

.sidebar-footer-list ul {
    padding: 0 2em;
}

.new-card-head h3 {
    font-size: 2.3em;
    font-weight: 600;
    margin: 0;
}

.category-tab-card {
    margin: 1em 0;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: auto;
    padding: 1em;
}

.category-tab-card ul {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 2em;
    align-items: center;
    margin: 0;
    width: max-content;

}

.category-tab-card ul li {
    list-style: none;
}

.category-tab-card ul li a {
    font-size: 1.4em;
    background-color: #f2f3f6;
    padding: 0.5em 1em;
    border-radius: 25px;
    color: #000000;
}

.category-total-card {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2em;
}
.moblie-category-icon-sec{
    display: none;
}