.order-list-sec {
  margin-top: 4em;
  padding-bottom: 4em;
}

.order-list-card {
  border: 1px #D5D9D9 solid;
  margin-top: 2em;
  border-radius: 8px;
}

.order-list-card .order-list-header-sec {
  border-radius: 8px 8px 0 0;
  background-color: #F0F2F2;
  padding: 2em;
}

.order-list-sec .table thead.thead-primary {
  background: var(--primary-color);
}

.order-list-sec .table thead th {
  border: none;
  padding: 20px;
  font-size: 13px;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
}

.order-list-sec .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.order-list-sec .table tbody tr {
  margin-bottom: 10px;
}

.order-list-sec .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.order-list-sec .table tbody th,
.order-list-sec .table tbody td {
  border: none;
  padding: 20px;
  font-size: 14px;
  background: #fff;
  border-bottom: 4px solid #f8f9fd;
  vertical-align: middle;
  width: 25%;
}

.order-list-sec .table tbody td .img {
  width: 80px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.order-list-sec .order-list-flex {
  display: flex;
  align-items: center;
  width: 30%;
}

.order-list-sec .table tbody td .email {
  width: 20%;
  padding-left: 1em;
}

.order-list-sec .table tbody td .email span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4em;
  width: 15em;
}

.order-list-sec .table tbody td .email span:last-child {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
}

.order-list-sec .table-wrap {
  margin-top: 4em;
}

.order-view-btn {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  padding: 9px 30px !important;
  border-radius: 10px !important;
  text-align: center;
  color: #000000 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  cursor: pointer;
}

.order-view-btn:hover,
.order-view-btn:focus,
.order-view-btn:active {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #000000 !important;
}

/* Order Transaction CSS*/

.order-transaction-sec {
  margin-top: 4em;
  padding-bottom: 4em;
}

.order-list-card {
  border: 1px #D5D9D9 solid;
  margin-top: 2em;
  border-radius: 8px;
}

.order-list-card .order-list-header-sec {
  border-radius: 8px 8px 0 0;
  background-color: #F0F2F2;
  padding: 2em;
}

.order-transaction-sec .table thead.thead-primary {
  background: var(--primary-color);
}

.order-transaction-sec .table thead th {
  border: none;
  padding: 20px;
  font-size: 13px;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
}

.order-transaction-sec .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.order-transaction-sec .table tbody tr {
  margin-bottom: 10px;
}

.order-transaction-sec .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.order-transaction-sec .table tbody th,
.order-transaction-sec .table tbody td {
  border: none;
  padding: 20px;
  font-size: 14px;
  background: #fff;
  border-bottom: 4px solid #f8f9fd;
  vertical-align: middle;
  width: 25%;
}


.order-transaction-sec .table-wrap {
  margin-top: 4em;
}

.order-transaction-sec td {
  white-space: nowrap;
}

.order-transaction-sec .table-wrap p .fa-check-circle {
  color: #008000;
}

/*Order View CSS*/

.order-view-sec {
  margin-top: 80px;
  padding-bottom: 4em;
}

.order-view-card {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
  padding: 2em;
  border-radius: 10px;
  margin-top: 3em;
}

.order-view-summary-sec {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
  padding: 2em;
  border-radius: 10px;
  margin-top: 3em;
}

.order-view-img {
  width: 20em;
  height: 20em;
  object-fit: cover;
  margin-bottom: 2em;
}

.order-view-info h4 {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 1rem;
  border-bottom: 1px solid #ced4da;
  padding: 1em 0;
  font-size: 1em;
}

.order-view-info h4 span {
  font-weight: 400;
  font-size: 14px;
}

.order-view-info h4 a {
  color: #212529;
  line-height: 1.5;
  font-weight: 400;
  font-size: 14px;
}

.order-view-info h4 a:hover {
  color: var(--primary-color);
}

.order-view-info h4 p {
  margin-bottom: 0;
}

.border-bottom-zero {
  border-bottom: 0 !important;
}

.track {
  position: relative;
  /* background-color: #ddd; */
  /* height: 7px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* background-color: #ffffff; */
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
  /* padding: 2em; */
  border-radius: 10px;
  margin-top: 5em;
}

.track .step {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 25%;
  margin-top: -18px;
  text-align: center;
  position: relative;
}

.track .step.active:before {
  background: var(--primary-color);
}

.track .step::before {
  height: 7px;
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  top: 18px;
  background-color: #ddd;
}

.track .step.active .icon {
  background: var(--primary-color);
  color: #000000;
}

.track .icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: relative;
  border-radius: 100%;
  background: #ddd;
}

.track .step .text {
  font-weight: 500;
  color: #212529;
  font-size: 14px;
}

.track .step {
  text-align: center;
}

.track .text {
  display: block;
  margin-top: 12px;
  padding-bottom: 2em;
}

.track span.icon {
  margin-left: 0;
}

.sub-title,
.title {
  font-weight: 600;
  color: #8a96a3;
}

.sub-title {
  font-size: 1.6em;
  margin-bottom:0;
}

.product-details-body-sec .product-details-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1em;
}

.product-details-footer-sec h5 {
  font-size: 1.5em;
  font-weight: 500;
  color: #777;
  margin-bottom: 0;
}

.product-details-card h5 {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0;
}
.product-details-body-sec {
  border-bottom: 1px solid #ced4da!important;
  padding-bottom: 1em;
}
.product-details-footer-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1em;
}
.cancel-order {
  width: 7em;
  text-align: center;
  margin: auto;
}