.add-product-form {
  margin-top: 4em;
}

.add-product-btn {
  background-color: var(--primary-color) !important;
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  color: var(--quaternary-color) !important;
  display: inline-block;
  border: 1px solid var(--primary-color) !important;
  font-size: 1em;
  font-weight: 600;
}

.product-preview {
  width: 14em;
  margin: 10px auto 0;
}

.availability-sec h5 {
  color: #a7a7a7;
  font-weight: 400;
  font-size: 1.25rem;
}

.availability-sec h5 span {
  font-weight: 600;
  color: #222529;

}

.product-list-sec .table tbody td .product-img {
  width: 100px;
  height: 80px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.single-orders-view {
  background: var(--primary-color);
  padding: 9px;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  width: 100%;
  color: #000000;
  justify-content: center;
  font-size: 0.9em;
  font-weight: 600;
}

button.product-close.btn.btn-primary,
.product-view {
  background-color: #fff;
  color: #2c2828;
  border: 0px;
  font-size: 25px;
  padding: 0 5px;
  margin: 0;
  vertical-align: text-bottom;
}

.product-view:hover {
  color: #2c2828;
}

.product-list-action-icons {
  text-align: center;
}

.product-list-action-icons-sec svg {
  width: 2.5em !important;
  height: 1.8em !important;
  object-fit: contain;
  fill: rgb(0 0 0 / 50%);
}

.product-list-delete-icon {
  background-color: transparent !important;
  padding: 0 !important;
  border: 0 !important;
}

.product-list-action-icons-sec {
  display: flex;
  align-items: center;
  gap: 1em;
}

.product-details h4 {
  font-size: 1em;
  margin: 0 0 0.8em;
  font-weight: 500;
  color: var(--tertiary-color);
}

.product-details h6 {
  font-size: 0.8em;
  color: rgba(0, 0, 0, .3);
}

.product-delivery-status {
  display: inline-block;
  color: var(--secondary-color);
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.product-list-img-sec .product-list-img {
  height: 6em;
  aspect-ratio: 4/4;
  object-fit: contain;
}

.order-list-table.product-list-table table tbody tr td {
  padding: 2em 1em;
}

.order-list-table.product-list-table {
  margin: 0;
}

.tab-search-end.search-end-sec {
  margin-bottom: 2em;
}

.order-list-table.product-list-table table thead tr th:last-child {
  text-align: center;
}

.product-list-action-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.profile-details-form .form-control:focus {
  box-shadow: none;
  background: rgba(57, 57, 57, .07) !important;
}

.add-product-box {
  margin-top: 2em;
}

.profile-details-form .form-control.height-auto {
  height: auto !important;
}

.border-right-divider {
  padding-right: 2em;
}

.add-product-upload-box {
  border: 1px solid #e7e7e7;
  box-shadow: inherit;
  color: var(--active-color);
  height: 50px;
  background: rgba(57, 57, 57, .07);
  border-radius: 0.235rem;
  min-height: 16em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0em;
  height: 100%;
  justify-content: center;
}

.product-img-notes {
  margin-bottom: 1em;
  color: rgba(0, 0, 0, .5);
  font-size: 0.8em;
}

.upload-icon {
  width: 10em;
}


.single-product-full-img-sec .image-gallery-swipe .image-gallery-slides {
  background: var(--primary-color);
}

.image-gallery-thumbnail {
  background: var(--primary-color);
  border: 0 !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: 6em;
  height: 6em;
  object-fit: contain;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.single-product-full-img-sec .image-gallery-play-button {
  display: none;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 40px;
  width: 40px;
  stroke: #000;
}

.image-gallery-fullscreen-button .image-gallery-svg {
  stroke: #000;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  /* height: 32em; */
  object-fit: contain;
  height: calc(100vh - 250px);
}

.image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
  height: 100%;
}

.image-gallery-content.fullscreen .image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: 6em;
  height: 6em;
}

.single-product-sec {
  padding: 2em 0;
}

.single-product-review-card {
  display: flex;
  align-items: center;
  gap: 2em;
}

.single-product-info-sec .rating-star {
  margin-bottom: 0;
}

.single-product-info-sec h4 {
  font-size: 1.4em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.single-product-review-card .write-review-link {
  font-size: 1.1em;
  font-weight: 400;
  color: var(--tertiary-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.single-product-info-sec h3 {
  color: #FF3D3D;
  font-weight: 700;
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.availability-sec h5 {
  font-size: 0.9em;
}

.availability-sec {
  padding-bottom: 0.5em;
}

.single-product-info-sec h3 sub {
  bottom: 0;
}

.single-product-info-sec h3 del {
  color: var(--quaternary-color);
  font-weight: 500;
  font-size: 0.8em;
}

.single-product-review-card {
  padding: 0.6em 0;
}

.single-product-info-sec p {
  color: #a7a7a7;
  font-size: 0.8em;
  font-weight: 400;
  margin-bottom: 1em;
  word-break: break-all;
}

.single-product-info-sec p span {
  color: var(--tertiary-color);
}

.add-wishlist-sec svg {
  width: 1.8em;
}

.add-wishlist-sec {
  font-size: 1.1em;
  font-weight: 400;
  color: var(--tertiary-color);
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.5em 0;
}

.progress-bar-sec {
  padding: 0.5em 0;
}

.progress-bar-sec h5 {
  font-size: 1.1em;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-bottom: 1em;
}

.progress-bar-sec .progress {
  border-radius: 20px;
}

.progress-bar {
  background: linear-gradient(180deg, #FF4313 0%, #F6871D 50.83%, #EECE27 100%);
}

.single-product-quantity-sec {
  padding-top: 0.5em;
  padding-bottom: 1em;
}

.single-product-quantity-sec .form-control:focus {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%) !important;
}

.single-product-quantity-sec .react-numeric-input b {
  background-color: #3AC48B !important;
}

.single-product-quantity-sec .react-numeric-input i {
  background: var(--secondary-color) !important;
}

.single-product-quantity-sec .react-numeric-input .form-control {
  border: 0 !important;
  width: 8em;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.single-product-quantity-sec p {
  display: flex;
  align-items: center;
  gap: 1em;
}

.single-product-action-btn-sec .add-to-cart-btn {
  background-color: #232323 !important;
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  color: var(--secondary-color) !important;
  display: inline-block;
  border: 1px solid #232323 !important;
  font-size: 1.2em;
  font-weight: 500;
}

.single-product-action-btn-sec .add-to-cart-btn:hover,
.single-product-action-btn-sec .add-to-cart-btn:focus,
.single-product-action-btn-sec .add-to-cart-btn:active {
  background-color: #232323 !important;
  color: var(--secondary-color) !important;
  box-shadow: none !important;
}

.single-product-action-btn-sec {
  display: flex;
  align-items: center;
  gap: 1em;
}

.single-product-support-box {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 2em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2em;
  margin: 2em 0;
}

.single-product-support-card {
  display: flex;
  align-items: center;
  gap: 1em;
  font-weight: 700;
  color: #203C3D;
}

.single-product-support-icon {
  max-width: 4em;
}

.single-product-social-link li a svg {
  max-width: 2em;
  max-height: 2em;
  width: 2em;
  fill: var(--quaternary-color);
}

.single-product-social-link {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 0;
  margin-top: 2em;
}

.single-product-description-review-sec {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2em;
}

.single-product-description-review-sec .nav.nav-pills {
  justify-content: center;
  gap: 1em;
}

.single-product-description-review-sec .nav-link.active {
  background-color: #1E1E1E !important;
  padding: 0.6em 2em !important;
  color: var(--secondary-color) !important;
}

.single-product-description-review-sec .nav-link {
  font-size: 1.2em;
  font-weight: 500;
  background-color: #E1E1E1 !important;
  padding: 0.6em 2em !important;
  color: var(--tertiary-color) !important;
}

.single-product-description-review-sec .tab-content {
  padding-top: 4em;
}

.single-product-description-sec p {
  color: var(--tertiary-color);
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 2em;
}

.single-product-description-sec h4 {
  font-size: 1.6em;
  font-weight: 600;
  color: var(--tertiary-color);
}

.single-product-description-list {
  margin-bottom: 2em;
}

.single-product-description-list li {
  color: var(--tertiary-color);
  font-size: 1em;
  font-weight: 500;
}

.single-product-description-list li {
  padding-bottom: 0.5em;
}

.single-product-description-list li:last-child {
  padding-bottom: 0em;
}

.single-product-review-box {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 2em;
}

.single-product-review-left {
  display: flex;
  gap: 2em;
}

.single-product-review-user-img {
  width: 7em;
  height: 7em;
  object-fit: cover;
  border-radius: 50%;
}

.single-product-review-info p {
  color: var(--tertiary-color);
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 2em;
}

.single-product-review-info h4 {
  font-size: 1.6em;
  font-weight: 600;
  color: var(--tertiary-color);
}

.border-head-title {
  content: "";
  display: block;
  width: 40px;
  border-top: 2px solid #e7e7e7;
  margin: 1rem 0 1.25rem;
}

.rating-star-sec {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.rating-star-sec li svg {
  fill: var(--primary-color);
}

.rating-star-sec li:nth-child(5) svg {
  fill: rgb(0 0 0 / 50%);
}

.rating-desc {
  color: #a7a7a7;
  font-size: 0.8em;
  font-weight: 400;
  margin-bottom: 0.5em !important;
  word-break: break-all;
}

element.style {
  width: 18em;
  height: 12em;
  margin: auto;
}

.single-orders-view {
  background-color: var(--primary-color) !important;
  padding: 0.6em 1em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  color: var(--quaternary-color) !important;
  display: inline-block;
  border: 1px solid var(--primary-color) !important;
  font-size: 1em;
  font-weight: 600;
}